<template>
  <div class="invoice-page">
    <template v-if="canSend">
      <div class="header">
        <pdf ref="pdf" :src="pdfSrc" />
      </div>
      <van-form class="form" @submit="onSubmit">
        <van-field
          v-model="email"
          class="field"
          type="text"
          name="email"
          label="邮箱"
          label-width="36px"
          placeholder="请输入邮箱"
          :rules="rules"
        />
        <div class="btn-wrap">
          <van-button round block type="primary" native-type="submit">
            发送到邮箱
          </van-button>
        </div>
      </van-form>
    </template>
    <div v-else class="tip">
      发票开具中，请于5分钟之后进入查看。
    </div>
  </div>
</template>

<script>
import { invocieModel } from '../../api/invoice'
import { base64ToBlob } from '../../utils'
import Pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'

// 1.开票成功;2.发票（已下载）,3 已发邮件（成功）4.开票失败,5下载失败;6 邮件发送失败
// 以下情况才可以发送邮件
const SEND_STATUS = ['2', '3', '6']

export default {
  name: 'Invoice',
  components: {
    Pdf
  },
  data() {
    return {
      email: '',
      rules: [
        { required: true, message: '请输入邮箱' },
        { validator: this.validator, message: '请输入正确的邮箱地址' }
      ],
      pdfSrc: '',
      status: ''
    }
  },
  computed: {
    canSend() {
      return SEND_STATUS.includes(this.status)
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    validator(val) {
      const reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
      return reg.test(val)
    },
    async onSubmit() {
      await invocieModel.sendEmail(this.email, this.$route.params.orderNumber)
      this.$toast('操作成功，请去邮箱查收。')
      this.$router.push('/')
    },
    async initData() {
      const { data } = await invocieModel.query(this.$route.params.orderNumber)
      if (data) {
        this.pdfSrc = Pdf.createLoadingTask({
          url: (base64ToBlob(data.fileBase)),
          CMapReaderFactory
        })
        this.status = data.xgbz
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";

.invoice-page {
  padding: 32px;

  .tip {
    padding-top: 48px;
    text-align: center;
    font-size: @font-size-36;
    color: @theme-color;
  }

  .header {
    img {
      width: 100%;
    }
  }

  .form {
    margin-top: 24px;

    .field {
      height: 144px;
      padding: 0;
    }

    /deep/ .van-field__body {
      padding: 16px 24px;
      border: 1px solid @border-color;
    }

    /deep/ .van-field__label {
      padding-top: 16px;
    }

    .van-cell::after {
      border: none;
    }

    .van-button {
      margin-top: 24px;
      background: @theme-color;
      border-color: @theme-color;
    }
  }
}
</style>
